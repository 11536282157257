import { userTypes } from 'config/config';
import { Route, Routes } from 'react-router-dom';
import DashboardPage from 'screens/dashboard/Dashboard';
import PreferenceIndividual from 'screens/individual/PreferenceIndividual';
import ChangePassword from 'screens/password/ChangePassword';

import Layout from '../components/Layout';
import ProtectedRoute from './ProtectedRoute';

const IndividualRoutes = () => (
  <ProtectedRoute allowedRoles={[userTypes.individual]}>
    <Layout>
      <Routes>
        <Route path="dashboard/*" element={<DashboardPage />} />
        <Route path="preference" element={<PreferenceIndividual />} />
        <Route path="change-password" element={<ChangePassword />} />
      </Routes>
    </Layout>
  </ProtectedRoute>
);
export default IndividualRoutes;
