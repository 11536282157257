export const reducerCodes = {
  idToken: '11',
};

export const userTypes = {
  companyAdmin: 'Company admin',
  individual: 'Individual',
  superAdmin: 'Superadmin',
  corporateAdmin: 'Corporate admin',
  corporateStaff: 'Corporate staff',
  companyStaff: 'Company staff',
};
export const maxFileUploadAllowed = 5;
export const maxFileUploadReachedMessage = `*Max ${maxFileUploadAllowed} files allowed in one go`;
export const defaultErrorMessage = 'Something went wrong, try again later';
export const fileScanStatus = {
  scanned: 'OK',
  notScanned: 'NOT SCANNED',
};
export const logoFileName = 'logo.png';
export const MAX_FILE_SIZE_MB = 5; // 5mb size will be allowed
export const MAX_FILE_SIZE_MB_LARGE_FILES = 50; // 50 MB will be allowed
export const allowedFileTypes = [
  'image/jpeg',
  'text/csv',
  'image/gif',
  'image/svg+xml',
  'image/png',
  'text/plain', // Added for .txt files
];
export const largeFileTypes = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export const forcePasswordExpireDays = 40;
