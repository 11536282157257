import React, { useEffect, useState } from 'react';

import { Typography } from '@mui/material';
import { logoutUser } from 'common/utils';
import CustomModal from 'components/CustomModal';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'redux/store';

import { logout } from '../redux/slices/authSlice';

interface SessionTimeoutWrapperProps {
  children: React.ReactNode;
}

const SessionTimeoutWrapper: React.FC<SessionTimeoutWrapperProps> = ({
  children,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { expiresIn } = useSelector((state: RootState) => state.auth);
  const { authTime } = useSelector((state: RootState) => state['11']);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState('');

  useEffect(() => {
    const sessionExpiryTime = authTime + expiresIn; // Calculate the exact expiration time
    const timer = setInterval(() => {
      const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds since the epoch
      const remainingTime = sessionExpiryTime - currentTime;

      if (remainingTime <= 0) {
        setIsModalOpen(true);
        clearInterval(timer); // Stop the timer when the session expires
      } else {
        const minutes = Math.floor(remainingTime / 60);
        const seconds = remainingTime % 60;
        setTimeRemaining(`${minutes}m ${seconds}s`);
      }
    }, 1000);

    return () => clearInterval(timer); // Cleanup on unmount
  }, [authTime, expiresIn]);

  const handleLogout = () => {
    dispatch(logout());
    logoutUser();
    setIsModalOpen(false);
    navigate('/login');
  };

  return (
    <>
      <CustomModal
        open={isModalOpen}
        onClose={handleLogout}
        title="Session Expired"
        type="error"
        autoClose={false}
        primaryButtonAction={handleLogout}
        primaryButtonLabel="Login again"
      >
        <Typography variant="h6">
          Your session has expired. Please log in again.
        </Typography>
      </CustomModal>
      {!isModalOpen && (
        <Typography
          variant="subtitle2"
          style={{
            position: 'fixed',
            top: 0,
            right: 0,
            padding: '8px',
            backgroundColor: 'lightgray',
          }}
        >
          Session Expires In: {timeRemaining}
        </Typography>
      )}
      {children}
    </>
  );
};

export default SessionTimeoutWrapper;
