import { createTheme } from '@mui/material/styles';

import colors from './colors'; // Import color constants

// Define your light theme
const lightTheme = createTheme({
  palette: {
    mode: 'light', // Set to 'light' mode
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    success: {
      main: '#52C06D', // Custom success color
      light: '#5cd67a', // Optional: lighter shade
      dark: '#1d7a34', // Optional: darker shade
      contrastText: '#ffffff', // Text color for success components
    },

    // Define more colors as needed
  },
  typography: {
    fontSize: 12, // Set base font size
    // Customize typography if needed
    fontFamily: ['Poppins', 'Roboto', 'Arial'].join(','),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          // Apply a gradient background to all buttons
          textTransform: 'none',
          fontSize: '0.68rem', // Smaller button font size
          padding: '4px 8px', // Smaller padding
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small', // Set all TextFields to small size by default
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          fontSize: '0.775rem', // Compact font size
          padding: '0.45rem 0.8rem', // Increase top padding slightly to push text down
          lineHeight: '1.2', // Ensure proper line spacing
          height: 'auto', // Let the padding determine height naturally
          boxSizing: 'border-box', // Include padding in calculations
          paddingTop: '0.7rem',
        },
        root: {
          height: '2rem', // Total input height remains compact
          display: 'flex', // Flexbox for consistent alignment
          alignItems: 'center', // Center content vertically (fallback)
          boxSizing: 'border-box',
          '.MuiOutlinedInput-notchedOutline': {
            top: 0, // Keep the outline aligned correctly
          },
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '0.775rem', // Smaller label font
          transform: 'translate(0.875rem, 0.6rem) scale(1)', // Adjust label default position
        },
        shrink: {
          transform: 'translate(0.75rem, -0.4rem) scale(0.85)', // Shrinked label position
        },
      },
    },
  },

  spacing: 6,
  // Add other theme options if needed
});

// Define your dark theme
const darkTheme = createTheme({
  palette: {
    mode: 'dark', // Set to 'dark' mode
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    // Define more colors as needed
  },
  typography: {
    // Customize typography if needed
    // fontFamily: '"Poppins","Roboto", "Arial", sans-serif',
    fontFamily: ['Poppins', 'Roboto'].join(','),
  },
  // Add other theme options if needed
});

export { darkTheme, lightTheme };
