import { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Typography } from '@mui/material';
import { APIEndpoint } from 'common/enum';
import { universalPostRequest } from 'common/requestHandler';
import { userTypes } from 'config/config';
import { popupNotification } from 'notifications/ToastNotification';
import { useForm } from 'react-hook-form';

import GradientButton from '../../components/elements/GradientButton';
import HeaderComp from '../../components/elements/HeadingComponent';
import InputElement from '../../components/elements/InputElement';
import { AdminInterface } from './utils';
import { validationInviteAdmin } from './validation';
// import { validationInviteCustomer } from './validation';

const InviteAdminUser = () => {
  const [loading, setLoading] = useState(false);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<AdminInterface>({
    resolver: yupResolver(validationInviteAdmin),
    mode: 'onChange',
    defaultValues: {
      email: '',
      phone_number: '',
      companyName: '',
      firstName: '',
      lastName: '',
    } as AdminInterface,
  });
  const submitHandler = async (data: AdminInterface) => {
    setLoading(true);
    const response = await universalPostRequest(APIEndpoint.inviteAdminUser, {
      email: data.email,
      phone_number: data.phone_number,
      userType: userTypes.companyAdmin,
      firstName: data.firstName,
      lastName: data.lastName,
      companyName: data.companyName,
    });

    if ('error' in response) {
      popupNotification(response.error, false);
    } else {
      popupNotification(response.message, true);
      reset({} as AdminInterface);
    }
    setLoading(false);
  };
  return (
    <>
      <HeaderComp>Invite Audit company (Admin user)</HeaderComp>

      <Box display="flex" justifyContent="center" mt={5}>
        <Box
          width="80%"
          sx={{ boxShadow: '5px 10px 20px rgba(0, 0, 0, 0.1)' }}
          p={5}
          borderRadius={8}
        >
          <legend>
            <Typography variant="subtitle2">Admin user details</Typography>
          </legend>
          <form onSubmit={handleSubmit(submitHandler)}>
            <InputElement
              name="firstName"
              control={control}
              label="First name"
              type="text"
              rules={{ required: true }} // Rules can be added here
            />
            <InputElement
              name="lastName"
              control={control}
              label="Last name"
              type="text"
              rules={{ required: true }} // Rules can be added here
            />

            <InputElement
              name="email"
              control={control}
              label="Email"
              type="text"
              rules={{ required: true }} // Rules can be added here
            />
            <InputElement
              name="phone_number"
              control={control}
              label="Phone"
              type="text"
              rules={{ required: true }} // Rules can be added here
            />
            <InputElement
              name="companyName"
              control={control}
              label="Company name"
              type="text"
              rules={{ required: true }} // Rules can be added here
            />
            <Box textAlign="right" mt={4}>
              <GradientButton type="submit" rounded loading={loading}>
                Invite admin user
              </GradientButton>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  );
};

export default InviteAdminUser;
