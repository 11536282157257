import React from 'react';

import { ThemeProvider } from '@mui/material';
import 'App.scss';
import { userTypes } from 'config/config';
import { ToastNotificationContainer } from 'notifications/ToastNotification';
import PageNotFound from 'pages/404';
import LandingPage from 'pages/Landing';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';
import RedirectIfAuthenticated from 'routes/RedirectIfAuthenticated';
import RoleBasedRoutes from 'routes/RoleBasedRoutes';
import LoginPage from 'screens/auth/Login';
import ForgotPassword from 'screens/password/ForgotPassword/ForgotPassword';
import SetForgotPassword from 'screens/password/ForgotPassword/SetForgotPassword';
import ForgotUsername from 'screens/password/ForgotUsername/ForgotUsername';
import ResetPassword from 'screens/password/ResetPassword';
import SuperAdmin from 'screens/superAdmin/SuperAdmin';
import PasswordAgeWrapper from 'wrapper/PasswordAgeWrapper';
import SessionTimeoutWrapper from 'wrapper/SessionTimeoutWrapper';

import Layout from './components/Layout';
import MFAConfig from './components/MFA/MFAConfig';
import VerifyMFA from './components/MFA/VerifyMFA';
import UnauthorizedPage from './pages/UnauthorizedPage';
import ProtectedRoute from './routes/ProtectedRoute';
import { lightTheme } from './theme/theme';

const App: React.FC = () => {
  return (
    <ThemeProvider theme={lightTheme}>
      <ToastNotificationContainer />
      <Router>
        <Routes>
          {/* Public Route - Login Page */}
          {/* <Route path="/:uuid/login" element={<LoginPage />} /> */}
          <Route>
            <Route
              path="/"
              element={
                <RedirectIfAuthenticated>
                  <LandingPage />
                </RedirectIfAuthenticated>
              }
            />
            {/* <Route
              path="/login"
              element={
                <RedirectIfAuthenticated>
                  <LoginPage />
                </RedirectIfAuthenticated>
              }
            /> */}
            <Route path="/login" element={<LoginPage />} />
            {/* <Route path="/login" element={<InvoiceTemplate />} /> */}
            <Route path="/mfa-config" element={<MFAConfig />} />
            <Route path="/verify-mfa" element={<VerifyMFA />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route
              path="/v1/ConfirmForgotPassword/:username/*"
              element={<SetForgotPassword />}
            />
            <Route path="/forgot-username" element={<ForgotUsername />} />
          </Route>

          {/* Super admin Routes */}
          <Route
            element={
              <ProtectedRoute allowedRoles={[userTypes.superAdmin]}>
                <Layout />
              </ProtectedRoute>
            }
          >
            <Route path="dashboard" element={<SuperAdmin />} />
            <Route path="*" element={<PageNotFound />} />
          </Route>

          {/* End of Super admin Routes */}

          {/* Protected Route for User */}
          {/* <Route path="/:uuid/*" element={<RoleBasedRoutes />} /> */}
          <Route
            path="/:uuid/*"
            element={
              <PasswordAgeWrapper>
                <SessionTimeoutWrapper>
                  <RoleBasedRoutes />
                </SessionTimeoutWrapper>
              </PasswordAgeWrapper>
            }
          />

          {/* Unauthorized Route */}
          <Route path="/unauthorized" element={<UnauthorizedPage />} />

          {/* Catch-all route for unknown paths */}
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
