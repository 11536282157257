import { useEffect, useState } from 'react';

import InfoIcon from '@mui/icons-material/Info';
import { Box, Checkbox, Grid, TextField, Typography } from '@mui/material';
import { APIEndpoint } from 'common/enum';
import { addressFormInterface } from 'common/interface';
import {
  universalGetRequest,
  universalPutRequest,
} from 'common/requestHandler';
import useNavigateWithUUID from 'hooks/useNavigateWithUUID';
import { popupNotification } from 'notifications/ToastNotification';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import * as yup from 'yup';

import AddressUpdateComp from '../../components/address/AddressUpdateComp';
import GradientButton from '../../components/elements/GradientButton';
import FileUploadModal from '../../components/fileComponent/FileUpload';
import { useFileUpload } from '../../hooks/useFileUpload';

const validationSchema = yup.object().shape({
  groupEmailAddress: yup.string().email('Enter a valid email'),
});
const PreferenceAdmin = () => {
  const [notificationEnabled, setNotificationEnabled] = useState(false);
  const [groupEmailAddress, setGroupEmailAddress] = useState<string>('');
  const [loading, setLoading] = useState(true);
  const [adminEmail, setAdminEmail] = useState('');
  const { companyId, username, firstName, lastName } = useSelector(
    (state: RootState) => state.auth
  );
  const [userDefaultAddress, setUserDefaultAddress] = useState(
    {} as addressFormInterface
  );
  const [companyDefaultAddress, setCompanyDefaultAddress] = useState({} as any);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [emailError, setEmailError] = useState('');
  const { uploadFiles, progress, isUploading, setSelectedFiles, files } =
    useFileUpload();
  const { navigateWithUuid } = useNavigateWithUUID();
  const [userEmail, setUserEmail] = useState('');
  // ! This updates entity details
  const updateEntityDetails = async (companyAddress?: addressFormInterface) => {
    try {
      await validationSchema.validate({ groupEmailAddress });
      const updatedCompanyAddress = companyAddress ?? companyDefaultAddress;
      const data = {
        admin_user_email: adminEmail,
        ...updatedCompanyAddress,
        group_email: groupEmailAddress,
        notify_when_new_files_added_by_customers: notificationEnabled,
      };
      const payload = {
        companyId: companyId,
        entityDetails: JSON.stringify(data),
        actionName: 'company',
      };
      const response = await universalPutRequest(
        APIEndpoint.updateUserDetails,
        payload
      );
      if ('error' in response) {
        popupNotification(response.error, false);
      } else {
        popupNotification(response.message, true);
      }
    } catch (error: any) {
      setEmailError(error.message);
    }
  };
  const getCompanyDetails = async () => {
    setLoading(true);
    const response: any = await universalGetRequest(
      APIEndpoint.getUserDetails,
      {
        actionName: 'company',
        username: username,
        companyId,
      }
    );
    if ('error' in response) {
      popupNotification(response.error, false);
    } else {
      setCompanyDefaultAddress(response.data.companyDetails);
      setNotificationEnabled(
        response.data.companyDetails.notify_when_new_files_added_by_customers
      );
      setAdminEmail(response.data.companyDetails?.admin_user_email);
      setGroupEmailAddress(response.data.companyDetails?.group_email);
      setUserDefaultAddress(JSON.parse(response.data.address));
      setUserEmail(response?.data.email);
    }
    setLoading(false);
  };
  // !Upload company logo
  const handleUpload = async () => {
    await uploadFiles(
      companyId,
      'media',
      firstName,
      lastName,
      '',
      setShowModal,
      null
    );
  };
  useEffect(() => {
    getCompanyDetails();
  }, []);
  const abc = (data: addressFormInterface) => {
    updateEntityDetails(data);
  };
  return (
    <>
      <Grid container mt={5} justifyContent="center" gap={4}>
        <Grid item md={12}>
          <Box
            minWidth="100%"
            sx={{ boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)' }}
            p={4}
            borderRadius={8}
            display="flex"
            justifyContent="space-between"
          >
            <Box display="flex" flexDirection="column">
              <Box display="flex" gap={1}>
                <Typography variant="body2" fontWeight="bold">
                  Username:
                </Typography>
                <Typography variant="body2">{username}</Typography>
              </Box>
              <Box display="flex" gap={2}>
                <Typography variant="body2" fontWeight="bold">
                  Email:
                </Typography>
                <Typography variant="body2">{userEmail}</Typography>
              </Box>
            </Box>
            <Box>
              <GradientButton
                onClick={() => navigateWithUuid('change-password')}
              >
                Change password
              </GradientButton>
            </Box>
          </Box>
        </Grid>
        <Grid item lg={12}>
          <Box
            minWidth="100%"
            sx={{ boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)' }}
            p={4}
            borderRadius={8}
            justifyContent="space-between"
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography>Upload logo</Typography>
              <GradientButton rounded onClick={() => setShowModal(true)}>
                Upload logo
              </GradientButton>
            </Box>
            <Box
              mt={4}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="subtitle1" color="textDisabled">
                Notify when customer post files{' '}
              </Typography>
              <Checkbox
                checked={notificationEnabled}
                onChange={(val: React.ChangeEvent<HTMLInputElement>) =>
                  setNotificationEnabled(val.target.checked)
                }
              />
            </Box>
            <Box mt={4}>
              <Typography variant="subtitle1" color="textDisabled" mb={2}>
                Group email to receive notifications
              </Typography>
              <TextField
                fullWidth
                size="small"
                label="Group email"
                placeholder="Group email"
                value={groupEmailAddress}
                error={!!emailError}
                helperText={emailError || ''}
                onChange={(e) => {
                  {
                    setGroupEmailAddress(e.target.value);
                  }
                }}
              />
              <Box display="flex" mt={0.5}>
                <InfoIcon fontSize="small" color="info" />
                <Typography variant="subtitle2" color="textDisabled">
                  Admin will receive email notification if group email is null
                </Typography>
              </Box>
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <GradientButton rounded onClick={() => updateEntityDetails()}>
                Update
              </GradientButton>
            </Box>
          </Box>
        </Grid>

        <Grid item lg={6}></Grid>
      </Grid>
      <Typography variant="h6">Company address</Typography>
      <AddressUpdateComp
        handleSubmitForm={abc}
        addressDefaultValues={companyDefaultAddress}
      />
      <Typography variant="h6" mt={5}>
        Your address
      </Typography>
      <AddressUpdateComp addressDefaultValues={userDefaultAddress} />

      <FileUploadModal
        open={showModal}
        onClose={() => setShowModal(false)}
        onFilesSelected={setSelectedFiles}
        progress={progress}
        onUpload={handleUpload}
        uploading={isUploading}
        files={files}
      />
    </>
  );
};

export default PreferenceAdmin;
