import { useEffect, useState } from 'react';

import { Box, Button, Grid, Typography } from '@mui/material';
import { red } from '@mui/material/colors';
import { APIEndpoint } from 'common/enum';
import { customerGroupInterface } from 'common/interface';
import { universalPostRequest } from 'common/requestHandler';
import CustomModal from 'components/CustomModal';
import SelectableList from 'components/ListItemsWithSearch';
import useSelectableItems from 'hooks/useSelectableList';
import { popupNotification } from 'notifications/ToastNotification';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

import { getCustomerGroups } from './utils';

interface customerGroupMemberInterface {
  name: string;
}
//  This component deletes individual customers as well as corporate groups
const DeleteCustomerGroupMember = () => {
  const [customerGroups, setCustomerGroups] = useState(
    [] as customerGroupInterface[]
  );
  const [selectedCustomerGroup, setSelectedCustomerGroup] = useState(
    {} as customerGroupInterface
  );
  const [customerGroupMembers, setCustomerGroupMembers] = useState(
    [] as customerGroupMemberInterface[]
  );
  const { selectedItems, toggleItem, resetSelection } = useSelectableItems();
  const {
    selectedItems: selectedCustomerGroupMember,
    toggleItem: toggleMember,
    resetSelection: resetSelectedCustomer,
  } = useSelectableItems();
  const [loading, setLoading] = useState(false);
  const [deletingGroup, setDeletingGroup] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { companyId } = useSelector((state: RootState) => state.auth);
  useEffect(() => {
    getCustomerGroups(companyId, setCustomerGroups, setLoading);
  }, []);
  useEffect(() => {
    const filteredGroup = customerGroups.filter(
      (group) => group.companyCustomerGroupName == selectedItems[0]
    );
    setSelectedCustomerGroup(filteredGroup[0]);
    // setStaffGroupMembers(filteredGroup.flatMap((group) => group.customerIds));
    setCustomerGroupMembers(
      filteredGroup.flatMap((group) =>
        group.customerIds.map((id) => ({ name: id }))
      )
    );
  }, [selectedItems]);
  const getSelectedMemberId = (selectedMember: string) => {
    if (selectedCustomerGroup.customerType === 'Corporate Customer') {
      return selectedMember.split(' - ')[1];
    } else {
      return selectedMember.split(' -  - ')[1];
    }
  };
  const deleteMember = async () => {
    setDeletingGroup(true);
    const payload = {
      companyCustomerGroupId: selectedCustomerGroup.companyCustomerGroupId,
      companyId: selectedCustomerGroup.companyId,
      customerId: getSelectedMemberId(selectedCustomerGroupMember[0]),
      actionName: 'DeleteACustomer',
    };
    const response = await universalPostRequest(
      APIEndpoint.deleteGroup,
      payload
    );
    setDeletingGroup(false);
    if ('error' in response) {
      popupNotification(response.error, false);
    } else {
      popupNotification(response.message, true);
      getCustomerGroups(companyId, setCustomerGroups, setLoading);
      setCustomerGroupMembers([]);
      setSelectedCustomerGroup({} as customerGroupInterface);
    }
    resetSelection();
    resetSelectedCustomer();
  };
  return (
    <>
      <Typography variant="body1" mb={5} color="error">
        Delete customer group member
      </Typography>
      <Grid container spacing={4}>
        <Grid item lg={6}>
          <Typography variant="body1" mb={1} color="textDisabled">
            Customer groups
          </Typography>
          <SelectableList
            items={customerGroups}
            selectedItems={selectedItems}
            toggleItem={toggleItem}
            searchVisible={true}
            showSelectHighlight
            showCheckBoxIcon={false}
            allowSingleSelection
            loading={loading}
            idKey="companyCustomerGroupName" // Nested key
            labelKey="companyCustomerGroupDisplayName" // Nested label
            height={150}
          />
        </Grid>
        <Grid item lg={6}>
          <Typography variant="body1" mb={1} color="textDisabled">
            Customer group members
          </Typography>
          <Box
            height="100%"
            sx={{
              borderRadius: 4,
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            }}
          >
            {/* <List>
              {customerGroupMembers.map((member, index) => (
                <>
                  <ListItem key={member}>
                    <Typography variant="body2">{member}</Typography>
                  </ListItem>
                  {index !== customerGroupMembers.length - 1 && <Divider />}
                </>
              ))}
              {!customerGroupMembers.length && (
                <Typography variant="body1" textAlign="center" color={red[400]}>
                  No members
                </Typography>
              )}
            </List> */}
            <SelectableList
              items={customerGroupMembers}
              selectedItems={selectedCustomerGroupMember}
              toggleItem={toggleMember}
              searchVisible={true}
              showSelectHighlight
              showCheckBoxIcon={false}
              allowSingleSelection
              loading={loading}
              idKey="name" // Nested key
              labelKey="name" // Nested label
              height={150}
            />
          </Box>
        </Grid>
        <Grid lg={12}>
          <Box display="flex" justifyContent="flex-end" mt={8}>
            <Button
              variant="contained"
              color="error"
              disabled={selectedItems.length === 0}
              onClick={() => setShowModal(true)}
            >
              Delete member
            </Button>
          </Box>
        </Grid>
      </Grid>
      <CustomModal
        type="error"
        open={showModal}
        onClose={() => setShowModal(false)}
        primaryButtonAction={deleteMember}
        primaryButtonLabel="Delete member"
        secondaryButtonAction={() => setShowModal(false)}
        secondaryButtonLabel="Cancel"
        primaryButtonLoading={deletingGroup}
        title={`Delete member: ${selectedCustomerGroupMember[0]}`}
      >
        <Typography variant="body1">
          Customer {selectedCustomerGroupMember[0]} will be
          <Typography
            variant="body1"
            fontWeight="bold"
            sx={{ color: red[400] }}
            component="span"
          >
            {' '}
            Deleted{' '}
          </Typography>
        </Typography>
        <Typography variant="body1">Do you want to continue ?</Typography>
      </CustomModal>
    </>
  );
};

export default DeleteCustomerGroupMember;
