import { useState } from 'react';

import { Box, Button, TextField, Typography } from '@mui/material';
import CustomCard from 'components/CustomCard';
import CustomModal from 'components/CustomModal';
import HeaderComp from 'components/elements/HeadingComponent';
import useNavigateWithUUID from 'hooks/useNavigateWithUUID';

const ContractManagement = () => {
  const [showModal, setShowModal] = useState(false);
  const { navigateWithUuid } = useNavigateWithUUID();
  return (
    <>
      <HeaderComp>Contract management</HeaderComp>
      <CustomCard>
        <Typography variant="subtitle2" color="textDisabled">
          Do you want to enroll in contract management with e-signature ?
        </Typography>
        <Box display="flex" gap={4} mt={5}>
          <Button
            variant="contained"
            size="small"
            onClick={() => setShowModal(true)}
          >
            Yes
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={() => navigateWithUuid('dashboard')}
          >
            No
          </Button>
        </Box>
        <Box display="flex" mt={2} gap={1}>
          <Typography variant="subtitle2">Note: </Typography>
          <Typography variant="subtitle2" color="textDisabled">
            Service available in US and India
          </Typography>
        </Box>
      </CustomCard>
      <CustomModal
        type="info"
        title="Enroll for contract management with e-signature"
        primaryButtonLabel="Confirm"
        primaryButtonDisabled={true}
        primaryButtonAction={() => {}}
        open={showModal}
        onClose={() => setShowModal(false)}
        secondaryButtonLabel="Cancel"
        secondaryButtonAction={() => {}}
      >
        <Typography variant="subtitle2" fontWeight="light">
          Please type "I agree" to agree with the terms and conditions to
          proceed.
        </Typography>
        <Box display="flex" gap={4} mt={2} alignItems="center">
          {/* <FormControl>
            <FormControlLabel
              control={<Checkbox onChange={() => {}} name="gilad" />}
              label="I agree"
              color="primary"
            />
          </FormControl> */}
          <TextField
            autoFocus
            placeholder="Type here"
            size="small"
            sx={{ width: '70%' }}
          >
            please type the message
          </TextField>
        </Box>
      </CustomModal>
    </>
  );
};

export default ContractManagement;
