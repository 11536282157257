import { useEffect, useState } from 'react';

import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Box, FormHelperText, TextField, Typography } from '@mui/material';
import { APIEndpoint } from 'common/enum';
import { previousFileVersionInterface } from 'common/interface';
import { universalGetRequest } from 'common/requestHandler';
import {
  FileData,
  getUserFilesAndFolders,
  parseFilesAndFolders,
} from 'common/utils';
import { userTypes } from 'config/config';
import { popupNotification } from 'notifications/ToastNotification';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'redux/store';
import { folderNameRegex } from 'screens/superAdmin/validation';

import CustomModal from '../../components/CustomModal';
import CustomIconButton from '../../components/elements/IconButton';
import FileTable from '../../components/fileComponent/FileList';
import FileUploadModal from '../../components/fileComponent/FileUpload';
import PreviousVersionFileComp from '../../components/fileComponent/PreviousVersionFileComp';
import { handleFileUpload } from '../../components/fileComponent/utils';
import { createFolder } from './utils';

function DashboardPage() {
  const { uuid } = useParams();
  const [isModalOpen, setModalOpen] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [progress, setProgress] = useState<{ [key: string]: number }>({});
  const [uploading, setUploading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [newFolderName, setNewFolderName] = useState<string>('');
  const [fetchingFiles, setFetchingFiles] = useState(false);
  const [creatingFolder, setCreatingFolder] = useState(false);
  const [userFiles, setUserFiles] = useState([] as FileData[]);
  const [showFileVersionModal, setShowFileVersionModal] = useState(false);
  const [fetchingFileVersion, setFetchingFileVersion] = useState(false);
  const [previousFileVersions, setPreviousFileVersions] = useState(
    [] as previousFileVersionInterface[]
  );
  const [selectedFileForVersion, setSelectFileForVersion] =
    useState<FileData>();
  const { '*': dynamicPath } = useParams();
  const [path, setPath] = useState<string[]>(
    dynamicPath ? dynamicPath.split('/').filter(Boolean) : []
  );
  const { customerId, corporateId, role, firstName, lastName, accessToken } =
    useSelector((state: RootState) => state.auth);
  const folderId: string | null =
    role === userTypes.individual ? customerId : corporateId;

  const newFolderPath = path.join('/');
  const handleFilesSelected = (files: File[]) => {
    setUploadedFiles(files);
    const initialProgress: { [key: string]: number } = {};
    files.forEach((file) => {
      initialProgress[file.name] = 0;
    });
    setProgress(initialProgress);
  };
  const handleGetFileVersions = async (file: FileData) => {
    setSelectFileForVersion(file);
    setShowFileVersionModal(true);
    setFetchingFileVersion(true);
    // setLoading((prev) => ({ ...prev, fetchingFileVersion: true }));
    const response = await universalGetRequest(APIEndpoint.getFileVersions, {
      objectKey: `${file['file-path']}`,
    });
    if ('error' in response) {
      popupNotification(response.error, false);
    } else {
      const data: any = response.data;
      setPreviousFileVersions(data);
    }
    setFetchingFileVersion(false);
    // setLoading((prev) => ({ ...prev, fetchingFileVersion: false }));
  };

  const handleUpload = async () => {
    await handleFileUpload(
      uuid,
      folderId,
      firstName,
      lastName,
      uploadedFiles,
      newFolderPath,
      setModalOpen,
      setUploadedFiles,
      setUploading,
      setProgress,
      accessToken
    );
    fetchData();
  };
  const fetchData = async () => {
    setFetchingFiles(true);
    try {
      const response = await getUserFilesAndFolders(uuid, folderId);
      setFetchingFiles(false);
      if ('error' in response) {
        popupNotification('Something went wrong', false);
      } else {
        const parsedData: FileData[] = parseFilesAndFolders(response.data);
        setUserFiles(parsedData);
      }
    } catch (error) {
      setFetchingFiles(false);
      popupNotification('Failed to fetch files and folders', false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [uuid]);

  // !! Create folder
  const createNewFolder = async () => {
    if (!folderNameRegex.test(newFolderName) && !!newFolderName) return;
    setCreatingFolder(true);

    const response = await createFolder(
      `${uuid}/${folderId}/Home/${newFolderPath}`,
      newFolderName
    );
    if ('error' in response) {
      popupNotification(response.error, false);
    } else {
      popupNotification('Successfully created folder', true);
    }
    setNewFolderName('');
    fetchData();
    setShowModal(false);
    setCreatingFolder(false);
  };
  return (
    <>
      <Typography
        variant="h5"
        sx={{
          background: 'linear-gradient(45deg, #42d392, #647eff)', // Gradient colors
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          backgroundClip: 'text', // Add this for Firefox support
          display: 'inline-block', // Ensure the gradient is only applied to the text
          fontWeight: 'bold',
        }}
      >
        My documents
      </Typography>
      <Box display="flex" justifyContent="flex-end" gap={4} mr={2}>
        <CustomIconButton
          tooltipTitle="Create folder"
          buttonType="primary"
          onClick={() => setShowModal(true)}
        >
          <CreateNewFolderIcon />
        </CustomIconButton>
        <CustomIconButton
          tooltipTitle="Upload file"
          buttonType="primary"
          onClick={() => setModalOpen(true)}
        >
          <UploadFileIcon />
        </CustomIconButton>
      </Box>
      <FileTable
        userFiles={userFiles}
        path={path}
        setPath={setPath}
        loading={fetchingFiles}
        getFileVersions={handleGetFileVersions}
      />

      <FileUploadModal
        open={isModalOpen}
        onClose={() => setModalOpen(false)}
        onFilesSelected={handleFilesSelected}
        progress={progress}
        onUpload={handleUpload}
        uploading={uploading}
        files={uploadedFiles}
      />

      <CustomModal
        open={showModal}
        onClose={() => setShowModal(false)}
        type="info"
        title="Create new folder"
        primaryButtonLabel="Ok"
        secondaryButtonAction={() => setShowModal(false)}
        secondaryButtonLabel="Cancel"
        primaryButtonAction={() => {
          createNewFolder();
        }}
      >
        <Box pr={2}>
          <Typography variant="subtitle2" color="textDisabled" mb={2}>
            Type the name of the new folder you want to create
          </Typography>
          <TextField
            name="folderName"
            autoFocus
            required
            value={newFolderName}
            onChange={(
              e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => {
              setNewFolderName(e.target.value);
            }}
            fullWidth
            placeholder="Folder name"
            size="small"
          />
          {!folderNameRegex.test(newFolderName) && !!newFolderName ? (
            <FormHelperText error>
              Folder name can contain alphabets, digits, and - _ & symbols
            </FormHelperText>
          ) : (
            ''
          )}
        </Box>
      </CustomModal>

      {/* Modal to show versions of file */}
      {/* <CustomModal open={showFileVersionModal} onClose={()=>setShowFileVersionModal(false)}>

      </CustomModal> */}
      <PreviousVersionFileComp
        folderId={folderId}
        setPreviousFileVersions={setPreviousFileVersions}
        showFileVersionModal={showFileVersionModal}
        setShowFileVersionModal={setShowFileVersionModal}
        loading={fetchingFileVersion}
        previousFileVersions={previousFileVersions}
        fileName={selectedFileForVersion?.name || ''}
      />
    </>
  );
}

export default DashboardPage;
