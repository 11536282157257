import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { Box, Grid, Typography } from '@mui/material';

import GradientCardComponent from '../../components/elements/GradientCard';
import HeaderComp from '../../components/elements/HeadingComponent';
import useNavigateWithUUID from '../../hooks/useNavigateWithUUID';

const AccessControl = () => {
  const { navigateWithUuid } = useNavigateWithUUID();
  const options = [
    {
      label: 'Staff group',
      path: 'access-control/staff-groups',
    },
    {
      label: 'Customer group',
      path: 'access-control/create-customer-group',
    },
    {
      label: 'Mappings',
      path: 'access-control/mappings',
    },
  ];
  return (
    <>
      <HeaderComp>Access control</HeaderComp>
      <Grid container spacing={4}>
        {options.map((option) => (
          <Grid item lg={4} key={option.label}>
            <GradientCardComponent>
              <Box
                display="flex"
                gap={2}
                pt={1}
                pb={1}
                onClick={() => navigateWithUuid(option.path)}
              >
                <PersonAddAltIcon />
                <Typography variant="subtitle1" fontWeight="bold">
                  {option.label}
                </Typography>
              </Box>
            </GradientCardComponent>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default AccessControl;
