import { userTypes } from 'config/config';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { RootState } from 'redux/store';

import CompanyAdminRoutes from './CompanyAdminRoutes';
import CompanyStaffRoutes from './CompanyStaffRoutes';
import CorporateAdminRoutes from './CorporateAdminRoutes';
import CorporateStaffRoutes from './CorporateStaffRoutes';
import IndividualRoutes from './IndividualRoutes';

const RoleBasedRoutes = () => {
  const { role, accessToken } = useSelector((state: RootState) => state.auth);
  const location = useLocation();

  if (!role || !accessToken) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  // Conditionally render routes based on the user's role
  switch (role) {
    case userTypes.companyAdmin:
      return <CompanyAdminRoutes />;
    case userTypes.corporateStaff:
      return <CorporateStaffRoutes />;
    case userTypes.companyStaff:
      return <CompanyStaffRoutes />;
    case userTypes.individual:
      return <IndividualRoutes />;
    case userTypes.corporateAdmin:
      return <CorporateAdminRoutes />;
    case userTypes.companyStaff:
      return <CompanyStaffRoutes />;
    default:
      return <Navigate to="/unauthorized" state={{ from: location }} />;
  }
};
export default RoleBasedRoutes;
// // RoleBasedRoute.tsx

// import React from 'react';

// import { userTypes } from 'config/config';
// import { useSelector } from 'react-redux';
// import { Navigate } from 'react-router-dom';
// import { RootState } from 'redux/store';

// // Define a type for the role-based route component props
// interface RoleBasedRouteProps {
//   adminComponent: React.ReactNode;
//   userComponent: React.ReactNode;
// }

// // Dummy function to get the user role, replace with actual logic
// const RoleBasedRoute: React.FC<RoleBasedRouteProps> = ({
//   adminComponent,
//   userComponent,
// }) => {
//   //   const role = getUserRole(); // Get the user's role
//   const { role } = useSelector((state: RootState) => state.auth);
//   if (role === userTypes.admin || role === userTypes.staff) {
//     return <>{adminComponent}</>;
//   } else if (role === userTypes.individual) {
//     return <>{userComponent}</>;
//   } else {
//     return <Navigate to="/login" />;
//   }
// };

// export default RoleBasedRoute;
