export enum StatusCode {
  OKAY = 200,
  CREATED = 201,
  BAD_REQUEST = 400,
  NOT_FOUND = 404,
  INTERNAL_ERROR = 500,
}
const BASE_URL = process.env.REACT_APP_BASE_URL;

export const APIEndpoint = {
  changePassword: `${BASE_URL}/v1/ChangePassword`,
  confirmForgotPassword: `${BASE_URL}/v1/ConfirmForgotPassword`,
  createFolder: `${BASE_URL}/v1/CreateFolder`,
  createIndividualCustomerGroup: `${BASE_URL}/v1/CreateGroups`,
  createStaffGroup: `${BASE_URL}/v1/CreateGroups`,
  downloadFile: `${BASE_URL}/v1/DownloadFile`,
  forgotPassword: `${BASE_URL}/v1/ForgotPassword`,
  forgotUsername: `${BASE_URL}/v1/ForgotUsername`,
  getFileVersions: `${BASE_URL}/v1/GetPreviousVersions`,
  getGroups: `${BASE_URL}/v1/GetGroups`,
  getLogo: `${BASE_URL}/v1/GetLogo`,
  getUserDetails: `${BASE_URL}/v1/GetUserCompanyDetails`,
  getUserDirectory: `${BASE_URL}/v1/GetFoldersFiles`,
  getUsers: `${BASE_URL}/v1/GetUsers`,
  inviteAdminUser: `${BASE_URL}/v1/Signup`,
  inviteCorporateStaffUser: `${BASE_URL}/v1/Signup`,
  inviteIndividualCustomer: `${BASE_URL}/v1/Signup`,
  inviteNewCorporate: `${BASE_URL}/v1/Signup`,
  inviteStaffUser: `${BASE_URL}/v1/Signup`,
  login: `${BASE_URL}/v1/UserLogin`,
  logout: `${BASE_URL}/v1/Logout`,
  updateUserDetails: `${BASE_URL}/v1/UpdateEntityUserDetails`,
  uploadFile: `${BASE_URL}/v1/UploadFile`,
  validateMFAOTP: `${BASE_URL}/v1/VerifyMfaOTP`,
  verifyMFA: `${BASE_URL}/v1/VerifyMfaDevice`,
  createMapping: `${BASE_URL}/v1/CreateGroups`,
  deleteGroup: `${BASE_URL}/v1/CreateGroups`,
  getAnalytics: `${BASE_URL}/v1/GetDashboard`,
  closeActiveSessions: `${BASE_URL}/v1/closeActiveSessions`,
  getSecDocsMain: `${BASE_URL}/v1/GetSecDocsMain`,
  secDocsMain: `${BASE_URL}/v1/SecDocsMain`,
};
