import { AxiosResponse } from 'axios';

import { APIEndpoint, StatusCode } from './enum'; // Adjust the path as needed
import { universalGetRequest, universalPostRequest } from './requestHandler';

interface ErrorResponse {
  error: string;
}

interface ApiResponse {
  statusCode: number;
  body: string; // The body is a JSON string containing additional details
}

// Utility function to parse the error message from the response body
const parseErrorMessage = (body: string): string => {
  try {
    const parsedBody = JSON.parse(body);
    return parsedBody.message || 'An unexpected error occurred';
  } catch {
    return 'An unexpected error occurred';
  }
};

export const handleApiResponse = async <T>(
  requestPromise: Promise<AxiosResponse<ApiResponse> | ErrorResponse>
): Promise<T | string | undefined> => {
  try {
    const response = await requestPromise;

    if ('error' in response) {
      // Handle error response
      console.error('Error response:', response.error);
      return response.error;
    } else {
      // Handle successful response
      const axiosResponse = response as AxiosResponse<ApiResponse>;

      if (axiosResponse.data.statusCode === StatusCode.OKAY) {
        // Assuming the response body contains the actual data you want to return

        const parsedData: T = JSON.parse(axiosResponse.data.body) as T;
        return parsedData;
      } else {
        // Extract and parse error message from the response body
        const errorMessage = parseErrorMessage(axiosResponse.data.body);

        return errorMessage;
      }
    }
  } catch (error) {
    return 'An unexpected error occurred';
  }
};

export default handleApiResponse;

export const getUsers = async (
  companyId: string | undefined | null,
  actionName: string,
  employeeId: string | undefined | null,
  flowName?: string | null
) => {
  if (companyId) {
    const response: any = await universalGetRequest(APIEndpoint.getUsers, {
      companyId: companyId,
      actionName: actionName,
      flowName: flowName ?? 'ForUsers', // !This function/api is used in more than one screen
      employeeId,
    });
    return response;
  }

  // Check if the response is an error
};
export const getUserFilesAndFolders = async (
  companyId: string | undefined,
  customerId: string | undefined | null
) => {
  const response: any = await universalGetRequest(
    APIEndpoint.getUserDirectory,
    {
      folderPath: `${companyId}/${customerId}/Home/`,
    }
  );
  return response;
};

// !! Testing this remove it later
export interface FileData {
  id: string;
  type: 'file' | 'folder';
  name: string;
  modified: string;
  size?: string;
  items?: FileData[];
  'file-path'?: string;
  'uploaded-by'?: string;
  'scan-status'?: string;
}

interface ApiFile {
  type: 'file';
  name: string;
  modified: string;
  size: string;
  'file-path': string;
  'uploaded-by': string;
  'scan-status'?: string;
}

interface ApiFolder {
  type: 'folder';
  name: string;
  modified: string;
  items: {
    data: ApiFile[] | ApiFolder[];
    status: boolean;
    message: string;
  };
}

type ApiResponseItem = ApiFile | ApiFolder;

let idCounter = 1; // Global counter to generate unique IDs

// Function to parse API response into FileData
export function parseFilesAndFolders(data: ApiResponseItem[]): FileData[] {
  return data.map((item) => {
    if (item.type === 'file') {
      // Handle file
      const file: FileData = {
        id: String(idCounter++),
        type: 'file',
        name: item.name,
        modified: item.modified,
        size: item.size,
        'file-path': item['file-path'],
        'uploaded-by': item['uploaded-by'],
        'scan-status': item['scan-status'],
      };
      return file;
    } else {
      // Handle folder with recursive parsing of its items
      const folder: FileData = {
        id: String(idCounter++),
        type: 'folder',
        name: item.name,
        modified: item.modified,

        items:
          item.items && item.items.data.length > 0
            ? parseFilesAndFolders(item.items.data)
            : [],
      };
      return folder;
    }
  });
}

// Example API data to parse
const apiData: ApiResponseItem[] = [
  {
    type: 'folder',
    name: 'tax2022',
    modified: 'Unknown',
    items: {
      message: '',
      data: [
        {
          type: 'file',
          name: 'Tassali_Logos_new.png',
          modified: 'Sep 25, 2024',
          size: '30 KB',
          'file-path': 'asd',
          'uploaded-by': '',
        },
      ],
      status: true,
    },
  },
  {
    type: 'folder',
    name: 'tax2023',
    modified: 'Unknown',
    items: {
      message: '',
      data: [],
      status: true,
    },
  },
];

export function checkPasswordAge(
  lastPasswordChangeDate: string | null
): [boolean, boolean, number] {
  // Parse the input date
  if (!lastPasswordChangeDate) return [false, false, 0];
  const lastChange = new Date(lastPasswordChangeDate);
  const today = new Date();

  // Calculate the difference in time (in milliseconds)
  const timeDifference = today.getTime() - lastChange.getTime();

  // Convert the difference from milliseconds to days
  const differenceInDays = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  // Check if the difference is more than 35 or 40 days
  const isPasswordOld = differenceInDays > 40; // Mark password old if greater than 35 days
  const willPasswordExpire = differenceInDays > 35; // Mark password expired if greater than or equal to 40 days

  return [isPasswordOld, willPasswordExpire, differenceInDays];
}

// Parse the API response
// const parsedData: FileData[] = parseFilesAndFolders(apiData);
export const logoutUser = async (
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setLoading && setLoading(true);
  const response = await universalPostRequest(APIEndpoint.logout, {});
  setLoading && setLoading(false);
};
export const logoutUserOtherSessions = async (
  username: string,
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setLoading && setLoading(true);
  const response = await universalPostRequest(APIEndpoint.closeActiveSessions, {
    username,
  });
  setLoading && setLoading(false);
};
