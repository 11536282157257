import React, { useEffect, useState } from 'react';

import { Box, Button, Chip } from '@mui/material';
import { DataGrid, GridColDef, GridRowsProp } from '@mui/x-data-grid';
import { APIEndpoint } from 'common/enum';
import {
  universalGetRequest,
  universalPutRequest,
} from 'common/requestHandler';
import HeaderComp from 'components/elements/HeadingComponent';

// Define the type for the user data
interface User {
  username: string;
  status: string;
  accountStatus: string;
  name: string;
  profile: string;
}

const UserManagement: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [pageSize, setPageSize] = useState<number>(5);
  const [showModal, setShowModal] = useState(false);
  const [fieldValue, setFieldValue] = useState('');

  // Fetch active users
  const fetchActiveUsers = async () => {
    setLoading(true);
    try {
      const response: any = await universalGetRequest(
        APIEndpoint.getSecDocsMain,
        {
          httpMethod: 'GET',
          actionName: 'GetActiveUsers',
        }
      );
      setUsers(response.data); // Assuming the response contains a 'data' field
    } catch (error) {
      console.error('Error fetching active users:', error);
    } finally {
      setLoading(false);
    }
  };

  // Disable selected users
  const disableSelectedUsers = async () => {
    setLoading(true);
    try {
      await universalPutRequest(APIEndpoint.secDocsMain, {
        actionName: 'DisableUsers',
        usernames: selectedUsers,
        httpMethod: 'PUT',
      });
      fetchActiveUsers(); // Refresh the user list after disabling
    } catch (error) {
      console.error('Error disabling users:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchActiveUsers();
  }, []);

  // Define columns for DataGrid
  const columns: GridColDef[] = [
    {
      field: 'checkbox',
      headerName: '',
      width: 50,
      renderCell: (params) => (
        <input
          type="checkbox"
          checked={selectedUsers.includes(params.row.username)}
          onChange={() => toggleUserSelection(params.row.username)}
          style={{
            outline: 'none', // Removes blue border
            cursor: 'pointer', // Adds a pointer cursor
          }}
        />
        // <Checkbox size="small" />
      ),
    },
    { field: 'username', headerName: 'Username', width: 200 },
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'profile', headerName: 'Profile', width: 180 },
    {
      field: 'accountStatus',
      headerName: 'Account Status',
      width: 160,
      align: 'center',
      renderCell: (params) => (
        <Chip
          label={params.value}
          color={params.value === 'Active' ? 'success' : 'error'}
          variant="filled"
        />
      ),
    },
  ];

  // Toggle selection of user
  const toggleUserSelection = (username: string) => {
    setSelectedUsers((prevSelected) =>
      prevSelected.includes(username)
        ? prevSelected.filter((user) => user !== username)
        : [...prevSelected, username]
    );
  };

  return (
    <>
      <HeaderComp>User management</HeaderComp>
      <Box>
        <Button
          variant="contained"
          color="primary"
          onClick={disableSelectedUsers}
          disabled={selectedUsers.length === 0}
          style={{ marginBottom: '20px' }}
          size="small"
        >
          Disable Selected Users
        </Button>
      </Box>
      <div style={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={
            users.map((user) => ({
              ...user,
              id: user.username,
            })) as GridRowsProp
          } // Map users to add `id`
          columns={columns}
          pagination
          loading={loading}
          checkboxSelection={false} // Disable default checkbox column
        />
      </div>
    </>
  );
};
export default UserManagement;
