import { useCallback, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { Box, Grid, Typography } from '@mui/material';
import { APIEndpoint } from 'common/enum';
import {
  universalGetRequest,
  universalPostRequest,
} from 'common/requestHandler';
import { userTypes } from 'config/config';
import { popupNotification } from 'notifications/ToastNotification';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'redux/store';

import CustomDropdown from '../../../components/elements/CustomDropdown';
import GradientButton from '../../../components/elements/GradientButton';
import HeaderComp from '../../../components/elements/HeadingComponent';
import InputElement from '../../../components/elements/InputElement';
import SelectionCardComponent from '../../../components/elements/SelectionCard';
import { validateInviteCorporateUser } from './validation';

interface CorporateInviteInterface {
  email: string;
  phone_number: string;
  firstName: string;
  lastName: string;
  corporateName: string;
  corporate: string;
  // inviteType: string;
}

interface CorporateListInterface {
  id: string;
  name: string;
}

const InviteCorporate = () => {
  const [loading, setLoading] = useState(false);
  const { uuid } = useParams();
  const [inviteType, setInviteType] = useState<string>('null');
  const [corporateList, setCorporateList] = useState<CorporateListInterface[]>(
    []
  );
  const { companyId } = useSelector((state: RootState) => state.auth);
  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm<CorporateInviteInterface>({
    resolver: yupResolver(validateInviteCorporateUser),
    mode: 'onChange',
    defaultValues: {
      email: '',
      phone_number: '',
      firstName: '',
      lastName: '',
      corporateName: '',
    },
  });

  const corporateNameFromDropdown = watch('corporate');
  const submitHandler = async (data: CorporateInviteInterface) => {
    setLoading(true);
    if (inviteType === 'null') {
      return;
    }

    // Handle submission logic here...
    const response = await universalPostRequest(
      APIEndpoint.inviteNewCorporate,
      {
        email: data.email,
        phone_number: data.phone_number,
        userType:
          inviteType === 'new'
            ? userTypes.corporateAdmin
            : userTypes.corporateStaff,
        firstName: data.firstName,
        lastName: data.lastName,
        companyId: uuid,
        corporateName: data.corporateName,
        action: inviteType === 'new' ? 'new' : '',
      }
    );
    if ('error' in response) {
      popupNotification(response.error, false);
    } else {
      popupNotification(response.message, true);
      reset({} as CorporateInviteInterface);
    }
    setLoading(false);
    reset({} as CorporateInviteInterface);
    setLoading(false);
  };
  const getUsersList = useCallback(async () => {
    setLoading(true);
    const response: any = await universalGetRequest(APIEndpoint.getUsers, {
      companyId: companyId,
      actionName: 'GetCorporate',
      flowName: 'ForUsers',
    });

    if ('error' in response) {
      popupNotification(response.error, false);
    } else {
      setCorporateList(response.data);
    }
    setLoading(false);
  }, [companyId]);
  useEffect(() => {
    if (companyId) {
      getUsersList();
    }
  }, []);
  useEffect(() => {
    if (
      inviteType === 'existing' &&
      corporateNameFromDropdown &&
      corporateNameFromDropdown.length
    ) {
      setValue('corporateName', corporateNameFromDropdown.split(' - ')[0]);
    }
  }, [corporateNameFromDropdown]);
  return (
    <>
      <HeaderComp>Invite Corporate</HeaderComp>
      <Grid container spacing={4} mt={0}>
        <Grid item lg={4} md={6} sm={12}>
          <SelectionCardComponent isSelected={inviteType === 'new'}>
            <Box
              display="flex"
              gap={2}
              pt={2}
              pb={2}
              onClick={() => {
                setInviteType('new');
                reset();
                setValue('corporate', 'null');
              }}
            >
              <PersonAddAltIcon />
              <Typography variant="subtitle1" fontWeight="semibold">
                Invite new corporate
              </Typography>
            </Box>
          </SelectionCardComponent>
        </Grid>
        <Grid item lg={4} md={6} sm={12}>
          <SelectionCardComponent isSelected={inviteType === 'existing'}>
            <Box
              display="flex"
              gap={2}
              pt={2}
              pb={2}
              onClick={() => {
                setInviteType('existing');
                setValue('corporate', '');
                reset();
              }}
            >
              <PersonAddAltIcon />
              <Typography variant="subtitle1" fontWeight="semibold">
                Invite corporate staff user
              </Typography>
            </Box>
          </SelectionCardComponent>
        </Grid>
      </Grid>
      {inviteType !== 'null' && (
        <Box display="flex" justifyContent="center" mt={5}>
          <Box
            minWidth="100%"
            sx={{ boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)' }}
            p={5}
            borderRadius={8}
          >
            <legend>
              <Typography variant="subtitle2">
                {inviteType === 'new'
                  ? 'Corporate details'
                  : 'Corporate user details'}
              </Typography>
            </legend>
            <form onSubmit={handleSubmit(submitHandler)}>
              {inviteType == 'existing' && (
                <Box mt={1} mb={1}>
                  <CustomDropdown
                    name="corporate"
                    control={control}
                    options={corporateList}
                    label="Select corporate"
                    labelKey="name"
                    valueKey="name"
                    error={errors.corporate}
                    // Show error if present
                  />
                </Box>
              )}
              <InputElement
                name="firstName"
                control={control}
                label="First name"
                type="text"
                rules={{ required: true }}
              />
              <InputElement
                name="lastName"
                control={control}
                label="Last name"
                type="text"
                rules={{ required: true }}
                error={!!errors.lastName}
                helperText={errors.lastName?.message}
              />

              <InputElement
                name="corporateName"
                control={control}
                label="Corporate name"
                type="text"
                rules={{ required: true }}
              />

              <InputElement
                name="email"
                control={control}
                label="Email"
                type="text"
                rules={{ required: true }}
              />
              <InputElement
                name="phone_number"
                control={control}
                label="Phone"
                type="text"
                rules={{ required: true }}
              />
              <Box textAlign="right" mt={4}>
                <GradientButton type="submit" rounded loading={loading}>
                  {inviteType === 'new'
                    ? 'Invite corporate'
                    : 'Invite corporate user'}
                </GradientButton>
              </Box>
            </form>
          </Box>
        </Box>
      )}
    </>
  );
};

export default InviteCorporate;
